<template>
  <div>
    <v-row dense>
      <v-col md="3" cols="12">
        <deposit-slip-info v-on:data="on_selected_collector"
                           :collector="collector"></deposit-slip-info>
      </v-col>
      <v-col md="9" cols="12">
        <v-card flat dense v-if="this.collector_id != 0">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h5 class="font-weight-light">PAYMENTS SECTION</h5></v-toolbar-title>
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            <strong>
              <h3 class="font-weight-light">Overall Balance: {{ formatPrice(balance_format) }}</h3>
            </strong>
          </v-toolbar>
          <v-card-text>
            <v-col md="12" cols="12"></v-col>
            <v-row dense>
              <v-col md="5" cols="12">
                <v-select
                  class="mx-2"
                  :items="payment_for_data"
                  label="Payment For"
                  required
                  :rules="rules.combobox_rule"
                  v-model="payment_for"
                  dense
                  @change="reset"
                ></v-select>
              </v-col>
              <v-col md="7" cols="12" v-if="payment_for != '' && payment_for != 'Others Payment'">
                <v-combobox
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="search"
                  @keyup="searching($event.target.value)"
                  @change="get_search_items_info"
                  :items="search_items"
                  item-value="id"
                  item-text="name"
                  placeholder="Search by (Lastname/Firstname/CMF #/Account #)"
                  ref="memberCombobox"
                  dense
                ></v-combobox>
              </v-col>
              <v-col md="4" cols="12" v-if="payment_for != '' && payment_for != 'Others Payment'">
                <v-text-field v-model="id_no" label="ID #" dense outlined readonly></v-text-field>
              </v-col>
              <v-col md="8" cols="12" v-if="payment_for != '' && payment_for != 'Others Payment'">
                <v-text-field v-model="name" label="Name" dense outlined readonly></v-text-field>
              </v-col>
              <v-col md="12" cols="12" v-if="payment_for != ''">
                <payment-sections
                  v-on:data="on_save"
                  v-on:collector="on_save"
                  :collector_id="collector_id"
                  :payment_for="payment_for"
                  :input_amount="input_amount"
                  :selected_search_items="selected_search_items"
                  :deposit_slip_id="deposit_slip_id"
                  :balance="balance"
                  :or_series_available="or_series_available"
                  :or_series_available_id="or_series_available_id"
                  :transmital_or_id="transmital_or_id"
                  :is_govern="is_govern"
                  :govern_name="govern_name"
                ></payment-sections>
              </v-col>
              <v-col md="12" cols="12">
                <h2>Payments Data</h2>
                <payments-data
                  v-on:collector="on_save"
                  :collector_id="collector_id"
                  :payments_data="payments_data"
                ></payments-data>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import DepositSlipInfo from '@/views/payments/payment_for/PaymentsDepositSlipInfo'
  import PaymentSections from '@/views/payments/payment_for/PaymentSections'
  import PaymentsData from '@/views/payments/payment_for/PaymentsData'

  export default {
    components: {
      DepositSlipInfo,
      PaymentSections,
      PaymentsData,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return {
        payment_for_data: [],

        search: '',
        search_member: '',
        search_items: [],
        selected_search_items: null,

        collector: 0,
        collector_id: 0,
        deposit_slip_id: 0,
        balance: 0,
        balance_format: '0',
        or_series_available: 'NA',
        or_series_available_id: 0,
        transmital_or_id: 0,

        id_no: '',
        name: '',
        payment_for: '',
        input_amount: true,

        is_govern: false,
        govern_name: '',

        payments_data: [],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['user_id', 'branch_id']),
    },
    methods: {
      ...mapActions('deceased_client', ['search_deceased_client']),
      ...mapActions('kaagapay', ['search_kaagapay_unpaid']),
      ...mapActions('dayong', ['search_dayong_active']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        this.search = ''
        this.search_items = []
        this.search_member = ''
        this.selected_search_items = null
        this.id_no = ''
        this.name = ''
        this.input_amount = true
      },
      on_selected_collector(value) {
        this.govern_name = value.govern_name
        if (value.government_assistances_id != null) {
          this.is_govern = true
          this.payment_for_data = ['Service Payment']
        } else {
          this.is_govern = false
          this.payment_for_data = ['Service Payment', 'Kaagapay Payment', 'Others Payment', 'Dayong Fund']
        }
        this.search = ''
        this.search_items = []
        this.search_member = ''
        this.selected_search_items = null
        this.id_no = ''
        this.name = ''

        this.collector_id = value.collector_id
        this.deposit_slip_id = value.deposit_slip_id
        this.balance = parseFloat(value.balance)
        this.balance_format = this.balance
        this.or_series_available = value.or_series_available
        this.or_series_available_id = parseInt(value.or_series_available_id)
        this.transmital_or_id = parseInt(value.transmital_or_id)

        this.payments_data = value.payments_data
        this.collector = -1
      },
      on_save(value) {
        this.collector = value
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('search_word', value)
        switch (this.payment_for) {
          case 'Service Payment':
            this.input_amount = true
            this.search_deceased_client(data).then(response => {
              this.search_items = response.data
            })
            break
          case 'Kaagapay Payment':
            this.search_kaagapay_unpaid(data).then(response => {
              this.search_items = response.data
            })
            break
          case 'Dayong Fund':
            this.input_amount = true
            this.search_dayong_active(data).then(response => {
              this.search_items = response.data
            })
            break
          default:
            break
        }
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.selected_search_items = this.search_items[index]
            this.id_no = this.selected_search_items.id_no
            this.name = this.selected_search_items.name
          } else {
            this.name = ''
            this.id_no = ''
          }
        } else {
          this.name = ''
          this.id_no = ''
        }
      },
    },
  }
</script>
