<template>
  <div>
    <v-card flat dense>
      <v-form ref="form" lazy-validation>
        <v-flex xs12>
          <v-layout row wrap align-center>
            <v-flex xs12 md4>
              <v-text-field
                v-model="or_date"
                type="date"
                label="OR Date"
                class="mx-2"
                required
                :rules="rules.default_max_25_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4 v-if="payment_for != 'Kaagapay Payment'">
              <v-text-field
                v-model="or_no"
                label="OR No."
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 v-if="!input_amount">
              <v-select
                class="mx-3 pt-5"
                v-model="amount"
                :items="amount_due"
                item-value="due"
                item-text="due_number"
                label="Amount"
                required
                :rules="rules.combobox_rule"
              ></v-select>
            </v-flex>
            <v-flex xs12 md4 v-if="input_amount">
              <v-text-field
                v-model="amount"
                :min="0"
                type="number"
                label="Amount"
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex
              xs12
              md12
              v-if="
                payment_for === 'Service Payment' || payment_for === 'Dayong Fund' || payment_for === 'Others Payment'
              "
            >
              <v-text-field
                v-if="(payment_for === 'Service Payment' || payment_for === 'Dayong Fund') && !is_govern"
                v-model="payment_from"
                label="Payment From"
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-if="payment_for === 'Service Payment' && is_govern"
                v-model="govern_name"
                label="Payment From"
                class="mx-2"
                required
                readonly
              ></v-text-field>
              <v-select
                v-if="payment_for === 'Others Payment'"
                class="mx-2"
                :items="['Edit Info of Deceased', 'Canceled OR', 'Return Fund', 'Others']"
                label="Payment For"
                required
                :rules="rules.combobox_rule"
                v-model="details_others_payment"
                dense
              ></v-select>
              <v-combobox
                v-if="payment_for === 'Others Payment' && details_others_payment === 'Edit Info of Deceased'"
                class="mt-6"
                :append-icon="icons.mdiAccountSearchOutline"
                v-model="search"
                @keyup="searching($event.target.value)"
                @change="get_search_items_info"
                :items="search_items"
                item-value="id"
                item-text="name"
                placeholder="Search by (Account Name/Account #)"
                ref="memberCombobox"
                dense
                outlined
              ></v-combobox>
              <v-text-field
                v-if="payment_for === 'Others Payment' && details_others_payment != 'Edit Info of Deceased'"
                v-model="payment_from"
                label="Specify"
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_payments" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
              <v-btn color="warning" class="me-3 mt-4" @click="done_receipt" v-if="!saving"
                     v-show="is_govern"> Done
                Receipt
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-layout>
        </v-flex>
      </v-form>
    </v-card>
    <v-dialog v-model="remittances_dialog" persistent width="90%">
      <edit-info-deceased v-on:dialog_show="on_close_dialog"
                          :datus="deceased_information"></edit-info-deceased>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import EditInfoDeceased from './EditInfoDeceased'

export default {
  props: {
    collector_id: Number,
    payment_for: String,
    input_amount: Boolean,
    is_govern: Boolean,
    govern_name: String,

    selected_search_items: Object,

    deposit_slip_id: Number,
    balance: Number,
    or_series_available: String,
    or_series_available_id: Number,
    transmital_or_id: Number,
  },
  components: {
    snackBarDialog,
    EditInfoDeceased,
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  data() {
    return {
      encoded_id: 0,

      saving: false,
      alert: false,
      alert_message: '',

      or_date: '',
      or_no: '',

      amount: 0,
      amount_due: [],

      total_commission: 0,
      net_amount: 0,

      payment_from: '',
      details_others_payment: '',
      remittances_dialog: false,

      search: '',
      search_member: '',
      search_items: [],
      deceased_information: {},
    }
  },
  watch: {
    selected_search_items: function () {
      this.selected_payment_for()
    },
    search_member: function () {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.search_member === '') {
          this.search_items = []
        } else {
          this.searching_search_items(this.search_member)
        }
      }, 500)
    },
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['branch_id', 'month_of', 'user_id']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
  },
  methods: {
    ...mapActions('deceased_client', ['search_deceased_client']),
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('service_payment', ['save_service_payment', 'is_done_receipt_on_govern']),
    ...mapActions('kaagapay_payment', ['save_kaagapay_payment']),
    ...mapActions('dayong_fund', ['save_dayong_payment']),
    ...mapActions('others_payment', ['save_others_payment']),
    searching(value) {
      this.search_member = value
    },
    searching_search_items(value) {
      this.search_deceased_client({
        branch_id: this.branch_id,
        search_word: value,
      }).then(response => {
        this.search_items = response.data
      })
    },
    reset() {
      this.search = ''
      this.search_member = ''
      this.search_items = []
    },
    get_search_items_info() {
      this.amount = 0
      this.amount_due = []
      if (this.search_items.length > 0) {
        var index = this.search_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.search.id)
        if (index != -1) {
          this.deceased_information = this.search_items[index].data
          this.payment_from =
            this.deceased_information.last_name +
            ', ' +
            this.deceased_information.first_name +
            ' ' +
            this.deceased_information.middle_name
        } else {
          this.reset()
        }
      } else {
        this.reset()
      }
    },
    on_close_dialog(value) {
      this.remittances_dialog = value
    },
    selected_payment_for() {
      this.encoded_id = 0
      this.amount_due = []
      switch (this.payment_for) {
        case 'Kaagapay Payment':
          if (this.selected_search_items != null) {
            this.amount_due = this.selected_search_items.due_amounts
          }
          break
        default:
          break
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    done_receipt() {
      this.saving = true
      this.alert = false
      this.is_done_receipt_on_govern({
        or_no: this.or_series_available,
        series_or_id: this.or_series_available_id,
      })
        .then(() => {
          this.change_snackbar({
            show: true,
            color: 'success',
            text: 'OR SUCCESSFULLY PROCEED to NEXT!',
          })
          this.$emit('collector', this.collector_id)
          this.saving = false
        })
        .catch(error => {
          this.alert = true
          this.alert_message = error
          this.saving = false
        })
    },
    save_payments() {
      this.saving = true
      this.alert = false
      if (this.$refs.form.validate()) {
        var proceed = true
        var total_amount_to = this.amount
        if (this.balance <= 0) {
          if (!this.is_govern && this.balance - total_amount_to < -100) {
            proceed = false
            this.alert = true
            this.alert_message = 'Please Load Deposit Slip First'
            this.saving = false
          }
        }
        if (this.payment_for != 'Kaagapay Payment') {
          if (this.or_series_available === 0) {
            proceed = false
            this.alert = true
            this.alert_message = 'Please Load Or First'
            this.saving = false
          } else {
            var currentOr = this.or_series_available.split('-')
            if (currentOr.length > 0) {
              if (currentOr[0] != this.or_no) {
                proceed = false
                this.alert = true
                this.alert_message = 'Please Check Or No.'
                this.saving = false
              }
            } else {
              proceed = false
              this.alert = true
              this.alert_message = "OR can't split"
              this.saving = false
            }
          }
        }
        if (
          this.selected_search_items === null &&
          (this.payment_for === 'Service Payment' || this.payment_for === 'Dayong Fund')
        ) {
          proceed = false
          this.alert = true
          this.alert_message = 'Please Select Client First!'
          this.saving = false
        }
        if (parseFloat(this.amount) < 1) {
          proceed = false
          this.alert = true
          this.alert_message = 'Please Check the Amount!'
          this.saving = false
        }
        //if no error above then proceed
        if (proceed) {
          if (this.is_govern) {
            this.payment_from = this.govern_name
          }
          switch (this.payment_for) {
            case 'Kaagapay Payment': {
              if (this.selected_search_items == null) {
                this.alert = true
                this.alert_message = 'Please select Kaagapay Member First'
                this.saving = false
              } else {
                this.save_kaagapay_payment({
                  branch_id: this.branch_id,
                  kaagapay_id: this.selected_search_items.id,
                  deposit_slip_id: this.deposit_slip_id,
                  or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                  or_no: this.or_series_available,
                  amount: this.amount,
                  month_of: this.month_of,
                  balance: this.balance - this.amount,
                  series_or_id: this.or_series_available_id,
                  transmital_or_id: this.transmital_or_id,
                  collector_id: this.collector_id,
                  user_id: this.user_id,
                })
                  .then(() => {
                    this.change_snackbar({
                      show: true,
                      color: 'success',
                      text: this.payment_for + ' SUCCESSFULLY !',
                    })
                    this.$emit('collector', this.collector_id)
                    this.saving = false
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
              }
              break
            }
            case 'Service Payment': {
              this.save_service_payment({
                branch_id: this.branch_id,
                deceased_client_id: this.selected_search_items.id,
                deposit_slip_id: this.deposit_slip_id,
                or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                or_no: this.or_series_available,
                amount: this.amount,
                month_of: this.month_of,
                balance: this.balance - this.amount,
                series_or_id: this.or_series_available_id,
                transmital_or_id: this.transmital_or_id,
                collector_id: this.collector_id,
                user_id: this.user_id,
                payment_from: this.payment_from.toUpperCase(),
                is_govern: this.is_govern,
              })
                .then(() => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: this.payment_for + ' SUCCESSFULLY !',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
              break
            }
            case 'Dayong Fund': {
              this.save_dayong_payment({
                branch_id: this.branch_id,
                dayong_id: this.selected_search_items.id,
                deposit_slip_id: this.deposit_slip_id,
                or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                or_no: this.or_series_available,
                amount: this.amount,
                month_of: this.month_of,
                balance: this.balance - this.amount,
                series_or_id: this.or_series_available_id,
                transmital_or_id: this.transmital_or_id,
                collector_id: this.collector_id,
                user_id: this.user_id,
                payment_from: this.payment_from.toUpperCase(),
              })
                .then(() => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: this.payment_for + ' SUCCESSFULLY !',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
              break
            }
            case 'Others Payment': {
              this.save_others_payment({
                branch_id: this.branch_id,
                deposit_slip_id: this.deposit_slip_id,
                or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                or_no: this.or_series_available,
                amount: this.amount,
                month_of: this.month_of,
                balance: this.balance - this.amount,
                series_or_id: this.or_series_available_id,
                transmital_or_id: this.transmital_or_id,
                collector_id: this.collector_id,
                user_id: this.user_id,
                details: this.details_others_payment.toUpperCase() + ' ( ' + this.payment_from.toUpperCase() + ' )',
              })
                .then(() => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: this.payment_for + ' SUCCESSFULLY !',
                  })
                  this.$emit('collector', this.collector_id)
                  this.saving = false
                  if (this.details_others_payment === 'Edit Info of Deceased') {
                    this.remittances_dialog = true
                  }
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
              break
            }
            default:
              break
          }
        }
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving = false
      }
    },
  },
}
</script>
