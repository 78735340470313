<template>
  <v-card flat class="pa-3 mt-2">
    <v-toolbar dense dark color="primary">
      <v-toolbar-title><h4 class="font-weight-light">EDIT DECEASED PERSONAL INFOMATION</h4> </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="d-flex">
      <v-avatar rounded size="120" class="me-6">
        <v-img :src="cropped"></v-img>
      </v-avatar>
    </v-card-text>
    <v-card-text>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-row>
          <v-col md="3" cols="12">
            <v-text-field readonly v-model="branch" label="Branch" dense outlined></v-text-field>
          </v-col>
          <v-col md="3" cols="12">
            <v-text-field v-model="fsc_no" label="FSC No." dense outlined readonly></v-text-field>
          </v-col>
          <v-col md="3" cols="12">
            <v-text-field readonly v-model="date_of_service" label="Date of Service" dense outlined></v-text-field>
          </v-col>
          <v-col md="3" cols="12">
            <v-text-field readonly v-model="date_of_internment" label="Date of Interment" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card flat>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">DECEASED PERSONAL INFOMATION</h4> </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="last_name"
                    label="Last Name"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="first_name"
                    label="First Name"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="middle_name"
                    label="Middle Name"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="date_of_birth"
                    label="Date of Birth"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field v-model="age" label="Age" dense outlined readonly></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="date_of_death"
                    label="Date of Death"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="religion"
                    dense
                    outlined
                    label="Religion"
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="civil_status"
                    dense
                    outlined
                    label="Civil Status"
                    :items="['Single', 'Married', 'Widowed', 'Separated']"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="gender"
                    dense
                    outlined
                    label="Gender"
                    :items="['Male', 'Female']"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">FAMILY REPRESENTATIVES INFORMATION</h4> </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="representatives_name"
                    label="Name"
                    dense
                    outlined
                    :rules="rules.default_max_100_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="representatives_relationship"
                    label="Relationship"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="representatives_contact_no"
                    label="Contact #"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="representatives_address"
                    label="Address"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" @click="update_info" v-if="!saving"> Save changes </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate v-else></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color" :snackbar_text="this.snackbar_text" />
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    saving: false,
    alert: false,
    alert_message: '',

    id: '',
    fsc_no: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    date_of_birth: '',
    age: '',
    gender: '',
    civil_status: '',
    religion: '',
    representatives_name: '',
    representatives_address: '',
    representatives_contact_no: '',
    representatives_relationship: '',
    date_of_death: '',
    date_of_internment: '',
    date_of_service: '',
  }
}

export default {
  props: {
    datus: Object,
  },
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      cropped: require('@/assets/images/avatars/1.png'),
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.id = this.datus.id
    this.fsc_no = this.datus.fsc_no
    this.last_name = this.datus.last_name
    this.first_name = this.datus.first_name
    this.middle_name = this.datus.middle_name
    this.date_of_birth = this.datus.date_of_birth
    this.age = this.datus.age
    this.gender = this.datus.gender
    this.civil_status = this.datus.civil_status
    this.religion = this.datus.religion
    this.representatives_name = this.datus.representatives_name
    this.representatives_address = this.datus.representatives_address
    this.representatives_contact_no = this.datus.representatives_contact_no
    this.representatives_relationship = this.datus.representatives_relationship
    this.date_of_death = this.datus.date_of_death
    this.date_of_internment = this.datus.date_of_internment
    this.date_of_service = this.datus.date_of_service
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', [
      'branch',
      'branch_id',
      'year',
      'month_start',
      'month_end',
      'month_of',
      'user_id',
      'branch_address',
      'branch_contact_no',
    ]),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('deceased_client', ['update_info_of_deceased']),
    reset() {
      Object.assign(this.$data, initialState())
      this.$emit('dialog_show', false)
    },
    calculate_all_payables() {
      this.age = moment(this.date_of_service, 'YYYY-MM-DD').diff(moment(this.date_of_birth, 'YYYY-MM-DD'), 'years')
    },
    update_info() {
      this.saving = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('id', this.id)
        data.append('last_name', this.last_name.toUpperCase())
        data.append('first_name', this.first_name.toUpperCase())
        data.append('middle_name', this.middle_name.toUpperCase())
        data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'))
        data.append('age', this.age)
        data.append('gender', this.gender)
        data.append('civil_status', this.civil_status)
        data.append('religion', this.religion.toUpperCase())
        data.append('representatives_name', this.representatives_name.toUpperCase())
        data.append('representatives_address', this.representatives_address.toUpperCase())
        data.append('representatives_contact_no', this.representatives_contact_no.toUpperCase())
        data.append('representatives_relationship', this.representatives_relationship.toUpperCase())
        data.append('date_of_death', moment(this.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'))
        this.update_info_of_deceased(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.reset()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving = false
      }
    },
  },
}
</script>
