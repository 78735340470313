<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            ID
          </th>
          <th class="text-center text-uppercase">
            Name
          </th>
          <th class="text-center text-uppercase">
            OR Date
          </th>
          <th class="text-center text-uppercase">
            OR #
          </th>
          <th class="text-center text-uppercase">
            Net Collection
          </th>
          <th class="text-center text-uppercase">
            Payment For
          </th>
          <th class="text-center text-uppercase">
            Details
          </th>
          <th class="text-center text-uppercase">
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in payments_data"
          :key="item.id"
        >
          <td>{{ item.id_no }}</td>
          <td class="text-center">
            {{ item.name }}
          </td>
          <td class="text-center">
            {{ item.or_date }}
          </td>
          <td class="text-center">
            {{ item.or_no }}
          </td>
          <td class="text-center">
            {{ item.amount_number }}
          </td>
          <td class="text-center">
            {{ item.payment_from }}
          </td>
          <td class="text-center">
            {{ item.payment_for }}
          </td>
          <td class="text-center">
            <div
              v-if="payments_data.map(function(x) {return x.id; }).indexOf(item.id) == (payments_data.length-1)">
              <v-icon
                v-if="!is_deleting"
                class="mr-2"
                color="error"
                @click="delete_payment(payments_data.map(function(x) {return x.id; }).indexOf(item.id))"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-else></v-progress-circular>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiDelete} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
        },
        is_deleting: false,
      }
    },
    props: {
      payments_data: Array,
      collector_id: Number,
    },
    computed: {
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('service_payment', ['delete_service_payment']),
      ...mapActions('kaagapay_payment', ['delete_kaagapay_payment']),
      ...mapActions('dayong_fund', ['delete_dayong_payment']),
      ...mapActions('others_payment', ['delete_others_payment']),
      delete_payment(index) {
        this.is_deleting = true
        var data = this.payments_data[index]
        var splited_id = data.id.split('-')
        var payment_for = data.payment_for
        if (data.is_govern) {
          payment_for = 'SERVICE PAYMENT';
        }
        switch (payment_for) {
          case "KAAGAPAY PAYMENT": {
            this.delete_kaagapay_payment({
              kaagapay_payment_id: splited_id[0],
              kaagapay_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series,
              transmital_or_id: data.transmital_or_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
                this.is_deleting = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
                this.is_deleting = false
              })
            break
          }
          case "SERVICE PAYMENT": {
            this.delete_service_payment({
              service_payment_id: splited_id[0],
              deceased_client_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,
              is_govern: data.is_govern,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series,
              transmital_or_id: data.transmital_or_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
                this.is_deleting = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
                this.is_deleting = false
              })
            break
          }
          case "DAYONG FUND": {
            this.delete_dayong_payment({
              dayong_payment_id: splited_id[0],
              dayong_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series,
              transmital_or_id: data.transmital_or_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
                this.is_deleting = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
                this.is_deleting = false
              })
            break
          }
          case "OTHERS PAYMENT": {
            this.delete_others_payment({
              others_payment_id: splited_id[0],
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series,
              transmital_or_id: data.transmital_or_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
                this.is_deleting = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
                this.is_deleting = false
              })
            break
          }
          default:
            break
        }
      }
    },
  }
</script>
