<template>
  <v-card
    flat
    dense
  >
    <v-toolbar dense dark color="primary">
      <v-toolbar-title><h5 class="font-weight-light">DEPOSIT SLIP INFO</h5>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="12"
          cols="12"
        >
          <v-select
            v-model="collector_id"
            dense
            label="Collector Name"
            :items="collectors"
            item-value="id"
            item-text="name"
            :rules="rules.combobox_rule"
            @change="selected_collector"
          ></v-select>
          <v-text-field
            v-model="date_of_deposit"
            label="Date Deposited"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="time_of_deposit"
            label="Time Deposited"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="transaction_counter"
            label="Transaction Counter"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="deposit_amount"
            label="Deposit Amount"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="net_encoded"
            label="Net Encoded"
            dense
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            v-model="balance_in_this_deposit"
            label="Balance of This Deposit"
            dense
            outlined
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";

  export default {
    props: {
      collector: 0,
    },
    watch: {
      collector: function () {
        if (this.collector != -1) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('month_of', this.month_of);
          data.append('collector_id', this.collector);
          this.initialize_data(data)
            .then(response => {

              this.date_of_deposit = response.data[0].deposits.date_of_deposit
              this.time_of_deposit = response.data[0].deposits.time_of_deposit
              this.deposit_amount = response.data[0].deposits.amount
              this.transaction_counter = response.data[0].payments.length
              this.net_encoded = response.data[0].total_net_collection
              this.balance_in_this_deposit = response.data[0].balance_this_deposit

              var or_available = ''
              var or_id = 0
              var transmital_or_id = 0
              if (response.data[0].or_series_available != null) {
                or_available = response.data[0].or_series_available.or_series
                or_id = response.data[0].or_series_available.id
                transmital_or_id = response.data[0].or_series_available.transmital_or_id
              }
              var gov_name = '';
              if (response.data[0].deposits.government_assistance != null) {
                gov_name = response.data[0].deposits.government_assistance.name
              }
              this.$emit('data', {
                'government_assistances_id': response.data[0].deposits.government_assistances_id,
                'govern_name': gov_name,
                'collector_id': this.collector,
                'collector_name': response.data[0].name,
                'deposit_slip_id': response.data[0].deposits.id,
                'balance': response.data[0].deposits.balance_number,
                'or_series_available': or_available,
                'or_series_available_id': or_id,
                'transmital_or_id': transmital_or_id,
                'payments_data': response.data[0].payments,
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    data() {
      return {
        collectors: [],
        collector_id: 0,

        date_of_deposit: '',
        time_of_deposit: '',
        transaction_counter: 0,
        deposit_amount: 0,
        net_encoded: 0,
        balance_in_this_deposit: 0,
      }
    },
    created() {
      this.initialize()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'month_of', 'position']),
    },
    methods: {
      ...mapActions('payments', ['initialize_data']),
      initialize() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        data.append('month_of', this.month_of);
        data.append('collector_id', 0);
        this.initialize_data(data)
          .then(response => {
            this.collectors = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_collector() {
        if (this.collectors.length > 0) {
          var index = this.collectors.map(function (x) {
            return x.id;
          }).indexOf(this.collector_id)
          if (index != -1) {
            this.date_of_deposit = this.collectors[index].deposits.date_of_deposit
            this.time_of_deposit = this.collectors[index].deposits.time_of_deposit
            this.deposit_amount = this.collectors[index].deposits.amount
            this.transaction_counter = this.collectors[index].payments.length
            this.net_encoded = this.collectors[index].total_net_collection
            this.balance_in_this_deposit = this.collectors[index].balance_this_deposit

            var or_available = ''
            var or_id = 0
            var transmital_or_id = 0
            if (this.collectors[index].or_series_available != null) {
              or_available = this.collectors[index].or_series_available.or_series
              or_id = this.collectors[index].or_series_available.id
              transmital_or_id = this.collectors[index].or_series_available.transmital_or_id
            }
            var gov_name = '';
            if (this.collectors[index].deposits.government_assistance != null) {
              gov_name = this.collectors[index].deposits.government_assistance.name
            }
            this.$emit('data', {
              'government_assistances_id': this.collectors[index].deposits.government_assistances_id,
              'govern_name': gov_name,
              'collector_id': this.collector_id,
              'collector_name': this.collectors[index].name,
              'deposit_slip_id': this.collectors[index].deposits.id,
              'balance': this.collectors[index].deposits.balance_number,
              'or_series_available': or_available,
              'or_series_available_id': or_id,
              'transmital_or_id': transmital_or_id,
              'payments_data': this.collectors[index].payments,
            })
          }
        }
      }
      ,
    }
  }
</script>
